import Swal from 'sweetalert2';

export default class SweetAlert {

  static options = {
    title: 'Sure to delete?',
    text: 'This will delete this permanently',

    icon: 'warning',
    iconHtml: undefined,
    iconColor: undefined,

    confirmButtonColor: '#007ae1',
    confirmButtonText: 'Yes, delete it!',
    showCancelButton: true,
    cancelButtonColor: '#aaa',
    cancelButtonText: 'No, cancel!',

    allowOutsideClick: true,
    backdrop: true,

    color: '#545454',
    background: '#fff',
    customClass: {
      container: '',
      popup: '',
      header: '',
      title: '',
      closeButton: '',
      icon: '',
      image: '',
      content: '',
      htmlContainer: '',
      input: '',
      inputLabel: '',
      validationMessage: '',
      actions: '',
      confirmButton: '',
      denyButton: '',
      cancelButton: '',
      loader: '',
      footer: '.',
      timerProgressBar: '.'
    },

    // 'top', 'top-start', 'top-end', 'center', 'center-start', 'center-end', 'bottom', 'bottom-start', or 'bottom-end'.
    position: 'center',
    width: '32rem',
    padding: '1.25rem',
    grow: false, // 'row', 'column', 'fullscreen', or false
    showLoaderOnConfirm: true,
    preConfirm: undefined,
    showLoaderOnDeny: false,

    showClass: {
      popup: 'swal2-show',
      backdrop: 'swal2-backdrop-show',
      icon: 'swal2-icon-show'
    },
    hideClass: {
      popup: 'swal2-hide',
      backdrop: 'swal2-backdrop-hide',
      icon: 'swal2-icon-hide'
    },

    focusConfirm: false,
    focusDeny: true,

    // hooks
    willOpen: undefined, // before open
    didOpen: undefined, // after open
    willClose: undefined, // before close
    didClose: undefined, // after close
    didDestroy: undefined, // after destroy
  };

  /**
   *
   * @param options : Object[options]
   * @param confirmCb : CallableFunction
   * @param cancelCb : CallableFunction
   * @return {Promise<void>}
   */
  static async confirm (options = this.options, confirmCb, cancelCb) {

    // show confirm
    const confirm = await Swal.fire({
      ...this.options,
      ...options
    });

    // call confirm callable
    if (typeof confirmCb === 'function' && confirm.isConfirmed) {
      confirmCb();
    }

    // call cancel callable
    if (typeof confirmCb === 'function' && (confirm.dismiss || confirm.isDenied)) {
      cancelCb();
    }

  }

  /**
   *
   * @param options : Object[options]
   * @param confirmCb : CallableFunction
   * @param cancelCb : CallableFunction
   */
  static confirmError (options = this.options, confirmCb, cancelCb) {

    const newOptions = {
      ...options,
      iconHtml: '<i class="icon-delete_sweep"></i>',
      iconColor: '#d70404',
      confirmButtonColor: '#d70404'
    };

    this.confirm(newOptions, confirmCb, cancelCb);

  }

}
